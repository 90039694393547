<template>
  <v-container>
    <v-row justify="center">
    <h2 class="mb-6 mt-4">Pripomenúť koniec platnosti TK/EK</h2>
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-text-field
          class="mx-2"
          v-model="spz"
          :rules="spzRules"
          label="SPŽ"
          outlined
          dense
          required
        ></v-text-field>
        <v-text-field
          class="mx-2"
          v-model="dateString"
          label="Dátum konca platnosti TK/EK"
          outlined
          dense
          readonly
          required
          @click="() => (show_date_dialog = true)"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          class="mx-2"
          v-model="email"
          :rules="emailRules"
          label="Email"
          outlined
          dense
          required
        ></v-text-field>

        <v-text-field
          class="mx-2"
          v-model="phone"
          :rules="phoneRules"
          label="Telefón"
          outlined
          dense
          required
        ></v-text-field>
      </v-row>

      <v-row justify="center">
        <v-checkbox
          v-model="isEmail"
          :error="checkboxError"
          label="Poslať email"
          class="mx-2"
          @change="checkboxError = false"
        ></v-checkbox>
        <v-checkbox
          v-model="isSms"
          :error="checkboxError"
          label="Poslať SMS"
          class="mx-2"
          @change="checkboxError = false"
        ></v-checkbox>
      </v-row>

      <v-row>
        <v-btn
          :disabled="!valid"
          color="warning"
          class="mx-auto mt-4 px-8"
          @click="sendAndReset"
        >
          Upozorniť
        </v-btn>
      </v-row>
    </v-form>

    <!-- DATE PICKER -->
    <v-dialog
      v-model="show_date_dialog"
      max-width="400px"
      width="370px"
      close-delay="5"
    >
      <v-date-picker
        v-model="date"
        scrollable
        elevation="10"
        width="100%"
        @click:date="handleDatePicked"
        @dblclick:date="handleDatePicked"
        :first-day-of-week="1"
        locale="sk-SK"
      >
      </v-date-picker>
    </v-dialog>

    <v-snackbar v-model="snackbar" top :color="isError ? 'error' : 'success'">
      {{ isError ? "Vyskytla sa chyba" : "Všetko prebehlo úspešne" }}

      <template v-slot:action="{ attrs }">
        <v-btn text depressed v-bind="attrs" @click="snackbar = false">
          Zatvoriť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { createCron } from "@/data/api";

export default {
  name: "Pripomienky",

  data: () => ({
    snackbar: false,
    isError: false,
    valid: false,
    spz: "",
    spzRules: [(v) => !!v || "ŠPZ je povinné"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail je povinný",
      (v) => /.+@.+\..+/.test(v) || "E-mail musí byť platný",
    ],
    show_date_dialog: false,
    date: new Date().toISOString().slice(0, 10),
    phone: "",
    phoneRules: [(v) => !!v || "Telefónne číslo je povinné"],
    isEmail: false,
    isSms: false,
    checkboxError: false,
  }),

  methods: {
    async sendAndReset() {
      if (!this.isEmail && !this.isSms) {
        this.checkboxError = true;
        this.valid = true;
        return;
      }

      const data = {
        date: this.date + "T12:00:00",
        dateExpiration: this.date + "T12:00:00",
        spz: this.spz,
        email: this.email,
        phone: this.phone,
        emailReminder: this.isEmail,
        smsReminder: this.isSms,
        sendExpiration: true,
      };

      // send data
      await axios
        .post(createCron(), data)
        .then((response) => {
          if(response.data.status === "success"){
            this.isError = false

            this.spz = "";
            this.email = "";
            this.phone = "";
            this.date = new Date().toISOString().slice(0, 10);
            this.isEmail = false;
            this.isSms = false;
          }else{
            this.isError = true;
          }
        })
        .catch((error) => {
          console.log(error)
          this.isError = true;
        });

      this.snackbar = true;
      this.$refs.form.resetValidation();
    },

    handleDatePicked(value) {
      // hide dialog
      this.show_date_dialog = false;

      this.date = new Date(value).toISOString().slice(0, 10);
    },
  },

  computed: {
    dateString() {
      return new Date(this.date).toLocaleDateString("sk-SK") || "";
    },
  },
};
</script>

<style></style>
